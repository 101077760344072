<template lang="html" src="./optionsScreen.html"/>
<script>
import mixins from "@/mixins/mixins";
export default {
  mixins: [mixins],
  data() {
    return {
      windowWidth: 0,
      isDowngrade: false,
      webScreen: true,
      mobileScreen: false,
      plan: null,
      recurrence: "Mês",
      total: 0,
      planServices: [],
      newPlan: null,
      totalContractedValue: 0,
      addons: [],
      planCurrentValue: 0,
      allPlans: [],
      showPlans: false,
      alreadyPurchasedAddonsId: [],
      contractedAddonsCounter: 0,
      showMessageAlert: true,
      textButton: "Meu plano Atual",
    }
  },
  computed: {
    campaign () {
      return this.$route.meta ? this.$route.meta.campaign : false
    },
    canBeUpgraded () {
      if (this.allPlans.length) {
        const { planValue: val } = this.plan

        return !!this.allPlans.filter((p) => p.planValue >= val).length
      }
      return false
    },
  },
  created: function () {
    this.newPlan = this.$session.get("customerPlan")
    this.plan = this.$session.get("customerPlan")
    this.planServices = this.$session.get("planServices")
  },
  mounted() {
    const routeParams = this.$route.params

    if (routeParams.campaignErrorMessage) {
      this.$bvModal.show("modal-non-elegible")
    }

    this.getCustomerPlan()
    // this.getAddons()
  },
  methods: {
    contactFiloo() {
      this.whatsAppSupport("Olá, recebi uma campanha da Filoo e estou com dúvidas...")
      this.$bvModal.hide("modal-non-elegible")
    },
    clearAllOptions() {
      const vm = this;
      vm.addons = vm.addons.map(a => ({ ...a, checked: false }));
      vm.total = 0;
      document.getElementById("button-add").focus();
    },
    getCustomerPlan() {
      const vm = this;
      if (!vm.testNullUndefinedEmpty(vm.plan.partnerId)) {
        vm.planCurrentValue = vm.$session.get("planValue")
        vm.showPlans = true;
        vm.getPlans();
      }
    },
    getPlans() {
      const code = this.plan.multPlanCode
      this.axios.get(
        `${this.$store.state.filooServer}customerplan/multplancode/${code}`
      )
        .then(({ status, data }) => {
          if (status === 200) {
            this.allPlans = data.filter(plan => plan.planValue > this.plan.planValue)
          }
        })
        .catch(function (error) {
          console.error({
            error: error,
            endpoint: `${this.$store.state.filooServer}customerplan/multplancode/${code}`,
            method: "getPlans",
            http_method: "GET",
          });
        });
    },
    getAddons() {
      const vm = this;
      vm.axios
        .get(`${vm.$store.state.filooServer}new-integra/plans`)
        .then(function (response) {
          if (response.status === 200) {
            vm.fillAddonList(response.data);
          }
        })
        .catch(function (error) {
          console.error({
            error: error,
            endpoint: `${vm.$store.state.filooServer}new-integra/plans`,
            method: "getAddons",
            http_method: "GET",
          });
        });
    },
    fillAddonList(addonsList) {
      const vm = this;

      if (this.campaign) {
        const code = this.$route.query && this.$route.query.cp
          ? parseInt(this.$route.query.cp)
          : 31

        vm.addons = addonsList.filter(addon => addon.id === code)
      } else {
        const addons = addonsList.filter(addon => addon.id !== 31)

        for (const a in addons) {
          addons[a].checked = false
          addons[a].disabled = false
        }
        const onlyPaidServices = addons.filter(a => a.value > 0)
        vm.addons = onlyPaidServices.sort(function (a, b) {
          return a.value > b.value ? -1 : a.value < b.value ? 1 : 0
        })
        vm.getUserAddons()
      }
    },
    getUserAddons() {
      const patientId = this.$session.get("customerData").patientId;
      this.axios
        .get(`${this.$store.state.filooServer}patient/services/${patientId}`)
        .then(({ data, status }) => {
          if (status === 200) {
            this.fillUserAddonsList(data);
          }
        })
        .catch((error) => {
          console.error({
            error: error,
            endpoint: `${this.$store.state.filooServer}patient/services/${patientId}`,
            method: "getServices",
            http_method: "GET",
          });
        });
    },

    fillUserAddonsList({ acquiredPlanServices }) {
      this.contractedAddonsCounter = 0;
      if (!this.testNullUndefinedEmpty(acquiredPlanServices)) {
        this.addons = this.addons.filter((addon) => {
          return !acquiredPlanServices.some((ac) => {
            if (addon.id === ac.id) {
              addon.checked = true;
              addon.disabledField = true;
              this.contractedAddonsCounter++;
            }
          });
        });
      }

      const onlyPaidServices = this.addons.filter(a => a.value > 0 && a.checked == true);

      const onlyCheckedServices = this.addons.filter(a => a.checked == true);

      this.alreadyPurchasedAddonsId = onlyCheckedServices.map(a => a.id);
      this.alreadyPurchasedAddons = onlyPaidServices;
      this.totalContractedValue = this.showPlans ? this.planCurrentValue : parseFloat(onlyPaidServices.reduce((a, b) => +a + +b.value, 0).toFixed(2));

      if (!this.testNullUndefinedEmpty(localStorage.getItem("planAndAddOnsData"))) {
        const planAndAddOnsData = JSON.parse(localStorage.getItem("planAndAddOnsData"));
        const addons = planAndAddOnsData.addons;
        this.total = planAndAddOnsData.total;
        this.addons = addons;
      }
    },
    goToProductsPage(id) {
      const vm = this;
      const planAndAddOnsData = {
        plan: vm.plan,
        addons: vm.addons,
        total: parseFloat(vm.total),
        knowMoreAddonId: id,
      };
      vm.$router.push({
        name: "products-know-more",
        params: {
          planAndAddOnsData: planAndAddOnsData,
        },
      });
      localStorage.setItem("planAndAddOnsData", JSON.stringify(planAndAddOnsData));
    },
    checkAlreadyContractedEqualsSelectedNow() {
      const vm = this;
      this.$gtm.trackEvent({
        // click_btn_pc_planupgrade_comprar
        event: "plan_upgrade_acquire_buy_btn_click",
        category: "Upgrade",
        action: "plan_upgrade_acquire_buy_btn_click",
        params: vm.addons.filter(a => a.checked == true),
        label: "Na página  de Upgrade de Plano no Portal Cliente - Clicar no botão ”Adquirir”",
      });

      const clickedAndAlreadyContracted = vm.addons.filter(a => a.checked == true);
      const clickedAndAlreadyContractedId = clickedAndAlreadyContracted.map(a => a.id);

      if (!vm.testNullUndefinedEmpty(clickedAndAlreadyContracted) && clickedAndAlreadyContracted.length > 0) {
        if (JSON.stringify(vm.alreadyPurchasedAddons) == JSON.stringify(clickedAndAlreadyContractedId)) {
          vm.showNotifyWarning("Serviço já contratado ", 3000);
        } else {
          vm.$bvModal.show("modal-upgrade-confirmation")
        }
      } else {
        vm.showNotifyWarning("Selecione ao menos um serviço para prosseguir com o upgrade do seu plano ", 6000);
        // vm.$router.push({
        //   name: "plan-downgrade",
        // });
      }

    },
    disableUpgrade() {
      let isDependent = false
      const planTypeId = this.$session.get("planTypeId");

      if (planTypeId === 4) {
        isDependent = true
      }

      if (isDependent) {
        return true
      }

      return false
    },
    alterPlan(plan) {
      const vm = this;

      vm.newPlan = plan;
      vm.$bvModal.show("modal-upgrade-confirmationPlan");

    },
    removeAlreadyPurchasedAddonsFromList(clickedAddons){
      const vm = this;
      var props = ["id", "name"];
      if (!vm.testNullUndefinedEmpty(vm.alreadyPurchasedAddons)) {
        var result = clickedAddons.filter(function(o1){
        // filter out (!) items in result2
          return !vm.alreadyPurchasedAddons.some(function(o2){
            return o1.id === o2.id;          // assumes unique id
          });
        }).map(function(o) {
        // use reduce to make objects with only the required properties
        // and map to apply this to the filtered array as a whole
          return props.reduce(function(newo, name) {
            newo[name] = o[name];
            return newo;
          }, {});
        });
        return result;
      } else {
        return clickedAddons;
      }
    },
    goToShoppingCartPlanUpgrade() {
      const vm = this;
      localStorage.setItem("newPlan", JSON.stringify(vm.newPlan));
      vm.$bvModal.hide("modal-upgrade-confirmationPlan");
      const planAndAddOnsData = {
        plan: vm.newPlan,
        totalOnlyNewSelectedAddons: vm.newPlan.planValue,
        isPlan: true,
      };
      localStorage.setItem("planAndAddOnsData", JSON.stringify(planAndAddOnsData));
      vm.$router.push({
        name: "shopping-cart",
        params: {
          planAndAddOnsData: planAndAddOnsData,
        },
      });
    },
    goToShoppingCart() {
      const vm = this;
      const clickedAndAlreadyContracted = vm.addons.filter(a => a.checked == true);
      const onlyNewAddons = vm.removeAlreadyPurchasedAddonsFromList(clickedAndAlreadyContracted);
      const clickedAddonsId = onlyNewAddons.map(a => a.id);
      if (!vm.testNullUndefinedEmpty(onlyNewAddons) && onlyNewAddons.length > 0) {
        const planAndAddOnsData = {
          plan: vm.plan,
          addons: vm.addons,
          clickedAddonsId: clickedAddonsId,
          totalOnlyNewSelectedAddons: parseFloat(vm.total - vm.totalContractedValue),
          total: parseFloat(vm.total),
          isPlan: false,
        };
        localStorage.setItem("planAndAddOnsData", JSON.stringify(planAndAddOnsData));
        vm.$bvModal.hide("modal-upgrade-confirmation");
        vm.$router.push({
          name: "shopping-cart",
          params: {
            planAndAddOnsData: planAndAddOnsData,
          },
        });

      } else {
        vm.showNotifyWarning("Selecione ao menos um serviço para prosseguir com o upgrade do seu plano ", 6000);
        vm.$bvModal.hide("modal-upgrade-confirmation");
      }
    },
  },
  watch: {
    addons: {
      handler(val) {
        if (val) {
          const onlyChecked = val.filter(a => a.checked)
          this.total = parseFloat(onlyChecked.reduce((a, b) => +a + +b.value, 0).toFixed(2))
        }
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" src="./optionsScreen.scss" />
